<template>
  <div class="work-data-box">
    <div class="select-box">
      <div class="statistics" :class="{ 'statistics-active': index == 1 }" @click="changeItem(1)">
        本次作业统计
      </div>
      <div class="statistics" :class="{ 'statistics-active': index == 2 }" @click="changeItem(2)">
        学员整体数据
      </div>
    </div>
    <div class="work-data" v-show="index == 1">
      <div class="block-box">
        <div class="block">
          <span class="num">{{ statistics.submitted }}人</span>
          <br />
          已提交
        </div>
        <div class="block">
          <span class="num">{{ statistics.notSubmitted }}人</span>
          <br />
          未提交
        </div>
      </div>
      <div class="charts-box">
        <div class="charts">
          <div class="title-box">
            <div class="title">
              各维度能力平均分
            </div>
          </div>
          <div id="myChart-Custom" :style="{ width: '405px', height: '451px', marginLeft: '50px' }"></div>
        </div>
        <div class="charts">
          <div class="title-box">
            <div class="title">
              得分情况
            </div>
          </div>
          <div id="myChart-Score" :style="{ width: '405px', height: '451px', marginLeft: '50px' }"></div>
        </div>
        <div class="charts-row">
          <div class="title-box">
            <div class="title">
              资源格式统计
            </div>
          </div>
          <div id="myChart-Resources" :style="{ width: '405px', height: '401px', marginLeft: '50px' }"></div>
        </div>
        <div class="charts">
          <div class="title-box">
            <div class="title">
              客观题正确率
            </div>
          </div>
          <div id="myChart-Objective" :style="{ width: '405px', height: '451px', margin: '0 auto' }"></div>
        </div>
        <div class="charts">
          <div class="title-box">
            <div class="title">
              第1题得分情况
            </div>
          </div>
          <div class="subject-box">
            <div class="title">
              {{ questionOne.question }}
            </div>
            <div class="option-box">
              <div style="margin-bottom:20px">
                <span class="option">{{ questionOne.a }}</span><span>{{ questionOne.b }}</span>
              </div>
              <div>
                <span class="option">{{ questionOne.c }}</span><span>{{ questionOne.d }}</span>
              </div>
            </div>
          </div>
          <div class="table-box">
            <el-table :data="rankingList" :header-cell-style="headerCellStyle" :cell-style="cellStyle" max-height="250">
              <el-table-column prop="ranking" width="83" align="center" label="排名">
              </el-table-column>
              <el-table-column prop="name" width="90" align="center" label="姓名">
              </el-table-column>
              <el-table-column prop="region" width="90" align="center" label="地区">
              </el-table-column>
              <el-table-column prop="school" width="90" align="center" label="学校">
              </el-table-column>
              <el-table-column prop="role" width="90" align="center" label="角色">
              </el-table-column>
              <el-table-column prop="option" width="90" align="center" label="选项">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="charts">
          <div class="title-box">
            <div class="title">
              主观题学员得分
            </div>
          </div>
          <div class="drop-down-box">
            <el-select v-model="value" style="width:110px" size="mini">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="subject-box">
            <div class="title">
              {{ questionSix }}
            </div>
          </div>
          <div class="charts-container">
            <div id="myChart-StudentScore" :style="{ width: '900px', height: '477px', margin: '0 auto' }"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="work-data" v-show="index == 2">
      <div class="charts-box">
        <div class="charts">
          <div class="title-box">
            <div class="title">
              能力图谱
            </div>
          </div>
          <div id="myChart-StudentData" :style="{ width: '405px', height: '405px', margin: '0 auto' }"></div>
        </div>
        <div class="charts">
          <div class="title-box">
            <div class="title">
              学员表现情况
            </div>
          </div>
          <div class="table-performance-box">
            <el-table :data="tableData" :header-cell-style="headerCellStyle" :cell-style="cellStyle">
              <el-table-column prop="secondaryCapability" width="180" align="center" label="二级能力">
              </el-table-column>
              <el-table-column prop="bestPerformance" width="180" align="center" label="表现最佳学员">
              </el-table-column>
              <el-table-column prop="needPromotion" width="180" align="center" label="亟需提升学员">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="charts-row">
          <div class="title-box">
            <div class="title">
              能力解析
            </div>
          </div>
          <div class="text-box">
            孙老师，您的学员能力报告如下：
            <br />
            <br />
            <img src="@/assets/biaoxianyouxiu.png" width="24" height="24" style="vertical-align: text-bottom;"
              alt="" /><span class="font-bold">表现优秀：</span>您的学员在<span
              class="important">{{ abilityAnalysis.biaoxianyouxiu }}</span>上有着优秀的表现，同时也存在着不足和亟待提升的方面需要您进行关注。
            <br />
            <br />
            <img src="@/assets/youdaitisheng.png" width="24" height="24" style="vertical-align: text-bottom;"
              alt="" /><span class="font-bold">适当关注：</span>学员们的
            <span class="important">{{ abilityAnalysis.shidangguanzhu }}</span>
            有待提升。在教学设计的各环节间缺少适当的层次与过渡，问题情境设计缺乏一定的吸引力，其次在教学活动的设计上，缺少对全班学生参与度的把控。第三，对课程资源不能进行合理筛选，导致课程资源不能有效融入到教学设计与教学实践中。
            <br />
            <br />
            <img src="@/assets/jixutisheng.png" width="24" height="24" style="vertical-align: text-bottom;"
              alt="" /><span class="font-bold">重点关注：</span>学员们亟待提升的能力包括<span class="important">{{
              abilityAnalysis.zhongdianguanzhu
            }}</span>。学员们的教学研究理论和方法知识储备不足，缺乏问题意识，缺少对新理论新方法的学习和运用。在教学设计、信息化运用等方面缺乏创新，同时对学生的创新意识的培养与引导也有所缺失。需要您进行重点关注。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    myChartAverageOption,
    myChartStudentDataOption,
    myChartScoreOption,
    myChartResourcesOption,
    myChartObjectiveOption,
    myChartStudentScoreOption
  } from "@/common/options";
  export default {
    data() {
      return {
        statistics: {
          submitted: "35",
          notSubmitted: "25"
        },
        index: 1,
        questionOne: {
          question: '问题1：下面哪一项不是“读整本书” 教学的策略。',
          a: 'A. 整体的学习情境',
          b: 'B. 具体的学习任务',
          c: 'C. 有趣的教学过程',
          d: 'D. 可见的学习成果'
        },
        questionSix: '问题6：根据此次讲座及个人的教学经验，围绕“读整本书”教学实施，从教学目标、教学内容、教学实施三个中选取一个，说说自己的收获或困惑。',
        value: "第6题",
        options: [{
          lebel: "第6题",
          value: "第6题"
        }],
        rankingList: [{
            ranking: "1",
            name: "高丽娜",
            region: "兴庆区",
            school: "实验小学",
            role: "参训教师",
            option: "C"
          },
          {
            ranking: "1",
            name: "张海燕",
            region: "兴庆区",
            school: "实验小学",
            role: "参训教师",
            option: "C"
          },
          {
            ranking: "1",
            name: "杨玲",
            region: "兴庆区",
            school: "实验小学",
            role: "参训教师",
            option: "C"
          },
          {
            ranking: "1",
            name: "马慧玲",
            region: "兴庆区",
            school: "实验小学",
            role: "参训教师",
            option: "C"
          },
          {
            ranking: "1",
            name: "刘虹",
            region: "贺兰县",
            school: "阅海二小",
            role: "参训教师",
            option: "C"
          },
          {
            ranking: "1",
            name: "史姝迪",
            region: "贺兰县",
            school: "阅海二小",
            role: "参训教师",
            option: "C"
          },
          {
            ranking: "1",
            name: "杨玉玉",
            region: "贺兰县",
            school: "阅海小学",
            role: "参训教师",
            option: "C"
          },
          {
            ranking: "1",
            name: "张蕾",
            region: "贺兰县",
            school: "阅海小学",
            role: "参训教师",
            option: "C"
          },
          {
            ranking: "2",
            name: "王一飞",
            region: "贺兰县",
            school: "阅海二小",
            role: "参训教师",
            option: "A（错误）"
          },
          {
            ranking: "2",
            name: "周玄",
            region: "兴庆区",
            school: "实验小学",
            role: "参训教师",
            option: "B（错误）"
          }
        ],
        abilityAnalysis: {
          biaoxianyouxiu: "课堂调控、学情分析、口语表达能力",
          shidangguanzhu: "教学过程设计能力、课程资源开发与利用能力 ",
          zhongdianguanzhu: "教学策略选择、学习研究与创新能力"
        },
        tableData: [{
            secondaryCapability: "学情分析能力",
            bestPerformance: "高丽娜、王静、马小燕",
            needPromotion: "杨玉玉、周悦、王丹妮"
          },
          {
            secondaryCapability: "教学过程设计能力",
            bestPerformance: "史姝迪、高丽娜、马佳",
            needPromotion: "张荣、孙娟、马丽娟"
          },
          {
            secondaryCapability: "教学策略选择能力",
            bestPerformance: "史姝迪、高丽娜、王静",
            needPromotion: "马霞、郭凤、王芳"
          },
          {
            secondaryCapability: "课程资源开发能力",
            bestPerformance: "杨玉玉、姜晓春、郭靖",
            needPromotion: "史姝迪、王鹤、王芳"
          },
          {
            secondaryCapability: "课程资源利用能力",
            bestPerformance: "杨玉玉、姜晓春、郭靖",
            needPromotion: "史姝迪、王鹤、王芳"
          },
          {
            secondaryCapability: "口语表达能力",
            bestPerformance: "张荣、孙娟、马丽娟",
            needPromotion: "马佳、李莉、赵丽娟"
          },
          {
            secondaryCapability: "课堂调控能力",
            bestPerformance: "史姝迪、高丽娜、郭靖",
            needPromotion: "马霞、郭凤、王芳"
          },
          {
            secondaryCapability: "学习研究能力",
            bestPerformance: "杨玉玉、高丽娜、马丽娟",
            needPromotion: "史姝迪、王静、王芳"
          },
          {
            secondaryCapability: "创新能力",
            bestPerformance: "杨玉玉、高丽娜、马丽娟",
            needPromotion: "史姝迪、孙娟、姜晓春"
          }
        ]
      };
    },
    methods: {
      changeItem(index) {
        this.index = index;
      },
      headerCellStyle({
        row,
        column,
        rowIndex,
        columnIndex
      }) {
        return "color:#FFFFFF;background:#2373EC";
      },
      cellStyle({
        row,
        column,
        rowIndex,
        columnIndex
      }) {
        if (rowIndex == 1 && columnIndex == 7) {
          return "color:#F33131";
        }
      },
      drawLine() {
        // 本次作业统计-各维度能力平均分
        let myChartCustom = this.$echarts.init(
          document.getElementById("myChart-Custom")
        );
        myChartCustom.setOption(myChartAverageOption);

        // 本次作业统计-得分情况
        let myChartScoreCustom = this.$echarts.init(
          document.getElementById("myChart-Score")
        );
        myChartScoreCustom.setOption(myChartScoreOption);

        // 本次作业统计-资源格式统计
        let myChartResources = this.$echarts.init(
          document.getElementById("myChart-Resources")
        );
        myChartResources.setOption(myChartResourcesOption);

        // 本次作业统计-客观题正确率
        let myChartObjective = this.$echarts.init(
          document.getElementById("myChart-Objective")
        );
        myChartObjective.setOption(myChartObjectiveOption);

        // 本次作业统计-主观题学员得分
        let myChartStudentScore = this.$echarts.init(
          document.getElementById("myChart-StudentScore")
        );
        myChartStudentScore.setOption(myChartStudentScoreOption);

        // 学员整体数据-各维度能力平均分
        let myChartStudentData = this.$echarts.init(
          document.getElementById("myChart-StudentData")
        );
        myChartStudentData.setOption(myChartStudentDataOption);
      }
    },
    mounted() {
      this.drawLine();
    }
  };
</script>

<style lang="less" scoped>
  /*媒体查询*/
  /*当页面大于1200px 时，大屏幕，主要是PC 端*/
  @media (min-width: 1200px) {
    .work-data-box {
      .work-data {
        width: 1200px;
        margin: 0 auto 260px;
        background: #ffffff;
      }

      .table-performance-box {
        margin-top: 50px;
        margin-left: 30px;
        padding-bottom: 50px;
      }
    }
  }

  /*在992 和1199 像素之间的屏幕里，中等屏幕，分辨率低的PC*/
  @media (min-width: 992px) and (max-width: 1199px) {
    .work-data-box {
      .work-data {
        width: 900px;
        margin: 0 auto 260px;
        background: #ffffff;
      }

      .table-performance-box {
        width: 540px;
        margin: 50px auto;
        padding-bottom: 50px;
      }
    }
  }

  .select-box {
    display: flex;
    margin-left: 68px;

    .statistics {
      cursor: pointer;
      width: 150px;
      height: 47px;
      background: rgba(36, 116, 236, 0.09);
      border-radius: 5px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #9c9c9c;
      line-height: 47px;
      margin-top: 41px;
      margin-right: 81px;
    }

    .statistics-active {
      width: 150px;
      height: 47px;
      background: #2474ec;
      border-radius: 5px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
      line-height: 47px;
      margin-top: 41px;
      margin-right: 81px;
    }
  }

  .block-box {
    width: 597px;
    height: 103px;
    // margin: 36px auto 0;
    margin-top: 36px;
    margin-left: 36px;
    background: rgba(36, 116, 236, 0.05);
    display: flex;

    .block {
      flex: 1;
      text-align: center;
      padding-top: 27px;

      .num {
        font-size: 30px;
        color: #2474ec;
        font-weight: bold;
      }
    }
  }

  .charts-box {
    display: flex;
    flex-wrap: wrap;

    .charts {
      flex: 1;
    }

    .charts-row {
      width: 100%;
    }

    .title-box {
      margin-left: 73px;
      margin-top: 122px;

      .title {
        border-left: 5px solid #2474ec;
        height: 23px;
        padding-left: 12px;
      }
    }

    .text-box {
      width: 85%;
      margin-top: 20px;
      margin-left: 100px;

      .font-bold {
        font-size: 18px;
        font-weight: bold;
      }

      .important {
        font-size: 18px;
        font-weight: bold;
        color: #2474ec;
      }
    }

    .subject-box {
      margin-left: 90px;

      .title {
        margin-top: 51px;
      }

      .option-box {
        margin-top: 33px;

        .option {
          margin-right: 133px;
        }
      }
    }

    .table-box {
      margin: 28px 33px 0;
      width: 533px;
    }

    .drop-down-box {
      margin: 23px 11px 23px 89px;
    }
  }
</style>