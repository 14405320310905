<template>
  <div class="stu_wrap">
    <span @click="showWork">{{ isShow ? "收起问卷" : "查看问卷" }}</span>
    <div class="workContent" v-if="isShow">
      <div class="container">
        <div class="center-board">
          <el-scrollbar class="center-scrollbar">
            <el-row class="center-board-row" :gutter="formConf.gutter">
              <el-form :size="formConf.size" :label-position="formConf.labelPosition" :disabled="formConf.disabled"
                :label-width="formConf.labelWidth + 'px'">
                <draggable class="drawing-board" :list="drawingList" :animation="340" group="componentsGroup"
                  :options="dragOptions">
                  <template v-if="worktype=='false'">
                    <draggable-item2 v-for="(item, index) in drawingList" :key="item.renderKey"
                      :drawing-list="drawingList" :current-item="item" :index="index" :active-id="activeId"
                      :form-conf="formConf" />
                  </template>
                  <template v-else>
                    <draggable-item v-for="(item, index) in drawingList" :key="item.renderKey"
                      :drawing-list="drawingList" :current-item="item" :index="index" :active-id="activeId"
                      :form-conf="formConf" />
                  </template>
                  <div class="fujian" v-for="(item, index) in fujianList" :key="'q' + index">
                    <div style="margin-left:20px;margin-bottom:10px">
                      {{ item.title }}
                    </div>
                    <div class="file" v-for="(items, index) in item.filelist" :key="'w' + index">
                      <i class="el-icon-tickets"></i>
                      <a :href="imgurl + items.id" target="_blank" style="margin-left:10px" @click="checkFile(items.id,2)">{{ items.name }}</a>
                    </div>
                    <el-image v-for="(items, index) in item.imglist" :key="'e' + index"
                      style="width: 200px;height:150px;margin:0 20px 20px 0;border-radius:5px;margin-left:20px"
                      :src="imgurl + items.id" :preview-src-list="item.srclist">
                    </el-image>
                    <video class="fujian_item" v-for="(items, index) in item.videolist" :key="'r' + index"
                      :src="imgurl + items.id" controls="controls" width="200px" height="150" @play="handlePlay(items.id)"
                      style="display:block;margin-left:20px" >
                      您的浏览器不支持播放该视频！
                    </video>
                    <audio class="fujian_item" style="display:block;margin-top:10px"
                      v-for="(items, index) in item.audiolist" :key="index" controls>
                      <source :src="imgurl + items.id" type="audio/mpeg" />
                      如果该元素不被浏览器支持，则本段文本被显示。
                    </audio>
                  </div>
                </draggable>
              </el-form>
            </el-row>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <div class="comment">
      <span class="checkComment" @click="showComment" v-if="isShowCom">查看评论<i class="el-icon-arrow-down"></i></span>
      <span class="checkComment" @click="showComment" v-else>收起评论<i class="el-icon-arrow-up"></i></span>
      <div class="flex-align" style="justify-content: flex-end"
        v-if="$store.state.user.role === 'expert' || $store.state.user.role === 'assistant'">
        <el-popover placement="top-start" width="500" trigger="click" v-model="visible">
          <div class="comment_wrap">
            <div>
              评分：
              <el-rate v-model="userStar"></el-rate>
            </div>
            <div style="display:flex;margin-top:10px">
              <span style="width:50px">点评:</span>
              <el-input type="textarea" class="textarea" :rows="5" placeholder="请输入内容" v-model="textarea">
              </el-input>
            </div>
            <div class="btn" style="display:flex;align-items:center;justify-content: space-around;margin-top:30px">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="subcomment(info.id)">保存</el-button>
            </div>
          </div>
          <span slot="reference" v-popover:popover class="flex-align">
            <!-- <img src="../../assets/comment.png" style="margin-right:10px" /> -->
            <span class="el-icon-edit-outline"></span>
            点评
          </span>
        </el-popover>
      </div>
    </div>
    <div class="line"></div>
    <div class="commentContent" v-if="!isShowCom">
      <div class="hascomment" v-if="info.correctingContent">
        <div class="teainfo flex-align">
          <img :src="imgurl + info.correctingUserCover" :onerror="imgUrl" />
          <span>{{ info.correctingUserName }}</span>
          <span style="margin-left:22px">点评于{{ info.correctingTime }}</span>
        </div>
        <div class="teacomment" style="white-space:pre-line">
          {{ info.correctingContent ? info.correctingContent.comment : "" }}
        </div>
      </div>
      <div class="nocomment" v-else>
        暂无评论
      </div>
    </div>
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import {
    debounce
  } from "throttle-debounce";
  import {
    saveAs
  } from "file-saver";
  import ClipboardJS from "clipboard";
  import render from "@/components/render/render";
  import JsonDrawer from "./JsonDrawer";
  import RightPanel from "./RightPanel";
  import {
    inputComponents,
    selectComponents,
    // layoutComponents,
    formConf
  } from "@/components/generator/config_view";
  import {
    exportDefault,
    beautifierConf,
    isNumberStr,
    titleCase,
    deepClone
  } from "@/utils/index";
  import {
    makeUpHtml,
    vueTemplate,
    vueScript,
    cssStyle
  } from "@/components/generator/html";
  import {
    makeUpJs
  } from "@/components/generator/js";
  import {
    makeUpCss
  } from "@/components/generator/css";
  import drawingDefalut from "@/components/generator/drawingDefalut";
  import logo from "@/assets/logo.png";
  import CodeTypeDialog from "./CodeTypeDialog";
  import DraggableItem from "./DraggableItem2";
  import DraggableItem2 from "./DraggableItem4";
  import {
    getDrawingList,
    saveDrawingList,
    getIdGlobal,
    saveIdGlobal,
    getFormConf
  } from "@/utils/db";
  import loadBeautifier from "@/utils/loadBeautifier";

  let beautifier;
  const emptyActiveData = {
    style: {},
    autosize: {}
  };
  let oldActiveId;
  let tempActiveData;
  const drawingListInDB = getDrawingList();
  const formConfInDB = getFormConf();
  const idGlobal = getIdGlobal();
  export default {
    components: {
      draggable,
      render,
      JsonDrawer,
      RightPanel,
      CodeTypeDialog,
      DraggableItem,
      DraggableItem2
    },
    props: {
      info: Object
    },
    data() {
      return {
        isShow: false,
        isShowCom: true,
        imgurl: this.downloadURL,
        imgUrl: require("../../assets/images/home/avatar.png"),

        UploadPicture: false, //上传图片
        Uploadname: "",
        kejianlist: [],
        dragOptions: {
          sort: false //定义生成的表单是否可以拖拽
        },
        logo,
        idGlobal,
        formConf,
        inputComponents,
        selectComponents,
        // layoutComponents,
        labelWidth: 100,
        drawingList: [],
        drawingData: {},
        activeId: drawingDefalut[0].formId,
        drawerVisible: false,
        formData: {},
        dialogVisible: false,
        jsonDrawerVisible: false,
        generateConf: null,
        showFileName: false,
        activeData: drawingDefalut[0],
        saveDrawingListDebounce: debounce(340, saveDrawingList),
        saveIdGlobalDebounce: debounce(340, saveIdGlobal),
        explain: "", //自定义的作业说明
        showExplain: true, //默认展开说明
        activeName: "first",
        powerVal: null, //评分（针对于每套问卷）
        workAttr: {}, //作业属性
        workTheme: "", //作业主题
        userData: "", //用户答题数据
        userInfo: "", //学员信息
        commentInfo: "", //评论信息
        subjectiveInfo: [], //主观题批改信息
        powers: "", //二级能力标准
        imgurl: this.downloadURL,
        imgUrl: 'this.src="' + require("../../assets/images/home/avatar.png") + '"',
        fujianList: [], //附件题数组
        videoTypes: [
          "mp4",
          "3gp",
          "m4v",
          "mkv",
        ],
        audioTypes: ["wav", "mp3", "aac", ],
        picTypes: ["bmp", "gif", "png", "jpg", "jpeg"],
        fileTypes: ["doc", "docx", "ppt", "pptx", "xls", "xlsx", "pdf", "txt", "wps", "zip", "rar"],
        visible: false, //专家评论框 显示或者隐藏
        userStar: null,
        textarea: "",
        checkArr: {
          comment: "",
          score: ""
        },
        // worktype:JSON.parse(this.info.qtitle).score
        worktype:this.info.qtitle.score
      };
    },
    watch: {
      // eslint-disable-next-line func-names
      "activeData.__config__.label": function (val, oldVal) {
        if (
          this.activeData.placeholder === undefined ||
          !this.activeData.__config__.tag ||
          oldActiveId !== this.activeId
        ) {
          return;
        }
        this.activeData.placeholder =
          this.activeData.placeholder.replace(oldVal, "") + val;
      },
      activeId: {
        handler(val) {
          oldActiveId = val;
        },
        immediate: true
      },
      drawingList: {
        handler(val) {
          this.saveDrawingListDebounce(val);
          if (val.length === 0) this.idGlobal = 100;
        },
        deep: true
      },
      idGlobal: {
        handler(val) {
          this.saveIdGlobalDebounce(val);
        },
        immediate: true
      }
    },
    mounted() {},
    methods: {
      showWork() {
        this.isShow = !this.isShow;
        this.fujianList = [];
        this.addpower();
        if(this.isShow){
            this.fujianList[0].imglist.map(item=>{
                this.checkFile(item.id,3)
            })
        }
        // this.getworkinfo();
      },
      showComment() {
        this.isShowCom = !this.isShowCom;
      },
      //查看当前问卷信息
      getworkinfo() {
        // console.log(this.info);
        //获取问卷题干
        let content = JSON.parse(this.info.qcontent);
        //取出作业说明
        content.map(item => {
          //修改表单默认值类型，不然会报错
          if (item.__config__.tag == "el-checkbox-group") {
            item.__config__.defaultValue = [];
          } else {
            item.__config__.defaultValue = "";
          }
          //将表单改为只读或者禁用状态
          if (item.__config__.tag == "el-input") {
            item.readonly = true;
          } else {
            item.disabled = true;
          }
        });
        //将作业说明过滤出去只显示问卷内容
        content = content.filter(item => {
          return item.__config__.tag != "tinymce";
        });
        //获取学员答案
        let resultlist = JSON.parse(this.info.content);
        // console.log("学员答案", resultlist);
        //将答案赋值给对应的题干
        // for (var i = 0; i < content.length; i++) {
        //   for (var j = 0; j < resultlist.length; j++) {
        //     if (content[i].__vModel__ == resultlist[j].id) {
        //       content[i].__config__.defaultValue = resultlist[j].result;
        //     }
        //   }
        // }

        for (let i = 0; i < content.length; i++) {
          for (let j = 0; j < resultlist.length; j++) {
            if (content[i].__vModel__ == resultlist[j].id) {
              content[i].__config__.defaultValue = resultlist[j].result
              resultlist.splice(j, 1)
              break;
            }
          }
        }

        console.log(content);
        //获取问卷正确答案
        // this.$Api.Form.getTeaForm(this.$route.query.questionId)
        this.$Api.Form.getTeaForm(this.info.questionnaireId)
          .then(value => {
            console.log('正确答案', value)
            let rightList = JSON.parse(value.data.answer);
            console.log('正确答案', rightList)
            content.map((item) => {
              if (rightList.hasOwnProperty(item.__vModel__) == true) {
                item.__config__.rightVal = rightList[item.__vModel__];
                if (item.__config__.tag == "el-radio-group") {
                  if (
                    item.__config__.defaultValue.toString() ==
                    item.__config__.rightVal.toString()
                  ) {
                    item.__config__.userScore = item.__config__.scoreValue;
                  } else {
                    item.__config__.userScore = 0;
                  }
                }
                if (item.__config__.tag == "el-checkbox-group") {
                  if (
                    item.__config__.defaultValue.sort().toString() ==
                    item.__config__.rightVal.sort().toString()
                  ) {
                    item.__config__.userScore = item.__config__.scoreValue;
                  } else {
                    item.__config__.userScore = 0;
                  }
                }
              }
              // item.__config__.defaultValue = item.__config__.defaultValue;
            });

          })
          .catch(err => {
            console.log(err)
          })
        //操作附件题
        content.map(item => {
          if (item.__config__.tag == "el-upload") {
            let obj = {};
            obj.srclist = [];
            obj.title = item.__config__.label;
            obj.id = item.__vModel__;
            if (item.__config__.defaultValue.length > 0) {
              obj.imglist = item.__config__.defaultValue.filter(item => {
                return this.picTypes.indexOf(item.type.toLowerCase()) != -1;
              });
              obj.videolist = item.__config__.defaultValue.filter(item => {
                return this.videoTypes.indexOf(item.type.toLowerCase()) != -1;
              });
              obj.audiolist = item.__config__.defaultValue.filter(item => {
                return this.audioTypes.indexOf(item.type.toLowerCase()) != -1;
              });
              obj.filelist = item.__config__.defaultValue.filter(item => {
                return this.videoTypes.indexOf(item.type.toLowerCase()) == -1 && this.picTypes.indexOf(item.type
                  .toLowerCase()) == -1 && this.audioTypes.indexOf(item.type.toLowerCase()) == -1;
              });
              obj.imglist.map(item => {
                obj.srclist.push(this.imgurl + item.id);
              });
            } else {
              obj.imglist = [];
              obj.videolist = [];
              obj.audiolist = [];
              obj.filelist = [];
              obj.srclist = [];
            }
            this.fujianList.push(obj);
          }
        });

        return content;
      },
      //添加能力标准
      async addpower() {
        let resData = await this.getworkinfo();
        // console.log('---',resData)
        this.$Api.Form.getPower(this.info.questionnaireId).then(res => {
          // console.log(res)
          resData.map(item => {
            if (res.data.hasOwnProperty(item.__vModel__) == true) {
              item.__config__.powerVal = res.data[item.__vModel__];
            }
          });
          resData = resData.filter(item => {
            return item.__config__.tag != "el-upload";
          });
          console.log(resData);

          //将处理好的主观题渲染到页面
          setTimeout(() => {
            localStorage.setItem("drawingItems", JSON.stringify(resData));
            this.drawingList = JSON.parse(localStorage.getItem("drawingItems"));
          }, 0)
        });
      },
      //取消评论
      cancel() {
        this.visible = false;
      },
      //提交评论
      subcomment(id) {
        if (this.userStar == 0 && !this.textarea.trim()) {
          this.$message({
            message: '请填写评分、点评任一项',
            type: 'warning'
          });
        } else {
          this.checkArr.comment = this.textarea;
          this.checkArr.score = this.userStar;
          let params = {
            correctingContent: JSON.stringify(this.checkArr),
            // correctingUserId: JSON.parse(sessionStorage.getItem("userinfo")).id,
            correctingUserId: this.$store.state.user.userInfo.id,
            id: id
          };
          this.$Api.Form.subComment(params)
            .then(res => {
              this.$message({
                type: "success",
                message: "提交成功"
              });
              this.textarea = "";
              this.userStar = null;
              // this.getSubInfo();
              this.$emit('getSubInfo')
              this.visible = false
            })
            .catch(err => {
              console.warn(err);
            });
        }

      },
      checkFile(identifier,type){
        let pramas = {
            identifier,
            type
          }
        this.$Api.Home.getDownloadUrl(pramas)
            .then(res=>{
                    console.log('res:',res)
            })
            .catch(err=>{
                console.log('err:',err)
            })
      },
      handlePlay(id){
        console.log('播放：',id);
        this.checkFile(id,3)
      }
    }
  };
</script>

<style lang="less" scoped>
  .stu_wrap>span:first-child {
    font-size: 12px;
    color: #E66201;
  }

  .stu_wrap {
    cursor: default;
    .line {
      width: 100%;
      height: 1px;
      background: #f2f2f2;
    }

    .commentContent {
      padding: 14px 0 10px;

      .hascomment {
        margin-bottom: 15px;
      }

      .teainfo {
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 19px;
        }

        span {
          font-size: 12px;
          color: #666666;
        }
      }

      .teacomment {
        margin-left: 50px;
        margin-top: 13px;
      }
    }

    .workContent {
      .container {
        width: 100%;
        background: #ffffff;

        .center-board {
          margin: 0;

          .fujian {
            .file {
              padding-left: 10px;
              margin: 10px 20px;
              height: 50px;
              display: flex;
              align-items: center;
              background: #EBEEF5;
              border-radius: 3px;
              width: 50%;

            }
          }
        }
      }
    }

    .comment {
      display: flex;
      justify-content: space-between;
      margin: 18px 0 8px;
      font-size: 12px;
      color: #666;

      .checkComment {
        font-size: 12px;
      }

      .el-icon-edit-outline {
        margin-right: 8px;
        font-size: 15px;
        color: #e66201;
      }


    }

    .nocomment {
      font-size: 12px;
      padding: 15px 0px 0;
    }
  }

  @media screen and (min-width:1250px) {
    cursor: default;
    .stu_wrap>span:first-child {
      font-size: 14px;
    }

    .stu_wrap {
      .commentContent {
        padding: 17px 0 0px;

        .hascomment {
          margin-bottom: 20px;
        }

        .teainfo {
          img {
            width: 36px;
            height: 36px;
            margin-right: 23px;
          }

          span {
            font-size: 14px;
          }
        }

        .teacomment {
          margin-left: 59px;
          margin-top: 17px;
        }
      }

      .comment {
        display: flex;
        justify-content: space-between;
        margin: 18px 0 8px;
        font-size: 14px;
        color: #666;

        .checkComment {
          font-size: 14px;
        }

        .el-icon-edit-outline {
          font-size: 17px;
        }


      }

      .nocomment {
        font-size: 12px;
        padding: 15px 0px 0;
      }
    }
  }
</style>