// 表单属性【右面板】
export const formConf = {
  formRef: "elForm",
  formModel: "formData",
  size: "medium",
  labelPosition: "top",
  labelWidth: 100,
  formRules: "rules",
  gutter: 15,
  disabled: false,
  span: 24,
  formBtns: true
}

// 输入型组件 【左面板】
export const inputComponents = [
  {
    // 组件的自定义配置
    __config__: {
      label: "问答题",
      labelWidth: null,
      showLabel: true,
      changeTag: true,
      tag: "el-input",
      tagIcon: "input",
      defaultValue: undefined,
      required: true,
      layout: "colFormItem",
      span: 24,
      document: "https://element.eleme.cn/#/zh-CN/component/input",
      // 正则校验规则
      regList: []
    },
    // 组件的插槽属性
    __slot__: {
      prepend: "",
      append: ""
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: "请输入",
    style: { width: "100%" },
    clearable: true,
    "prefix-icon": "",
    "suffix-icon": "",
    maxlength: null,
    "show-word-limit": false,
    readonly: false,
    disabled: false
  },
  {
    __config__: {
      label: "问答题",
      labelWidth: null,
      showLabel: true,
      tag: "el-input",
      tagIcon: "textarea",
      defaultValue: "正确答案",
      required: true,
      layout: "colFormItem",
      span: 24,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/input"
    },
    type: "textarea",
    // placeholder: '请输入',
    autosize: {
      minRows: 4,
      maxRows: 4
    },
    style: {
      width: "100%"
    },
    maxlength: null,
    "show-word-limit": false,
    readonly: false,
    disabled: false
  },
  {
    __config__: {
      label: "编辑器",
      showLabel: true,
      changeTag: true,
      labelWidth: null,
      tag: "tinymce",
      tagIcon: "rich-text",
      defaultValue: null,
      span: 24,
      layout: "colFormItem",
      required: true,
      regList: [],
      document: "http://tinymce.ax-z.cn"
    },
    placeholder: "请输入",
    height: 300, // 编辑器高度
    branding: false // 隐藏右下角品牌烙印
  }
]

// 选择型组件 【左面板】
export const selectComponents = [
  {
    __config__: {
      label: "单选题",
      labelWidth: null,
      showLabel: true,
      tag: "el-radio-group",
      tagIcon: "radio",
      changeTag: true,
      defaultValue: undefined,
      layout: "colFormItem",
      span: 24,
      optionType: "default",
      regList: [],
      required: true,
      border: false,
      document: "https://element.eleme.cn/#/zh-CN/component/radio"
    },
    __slot__: {
      options: [
        {
          label: "选项一",
          value: 1
        },
        {
          label: "选项二",
          value: 2
        }
      ]
    },
    style: {},
    size: "medium",
    disabled: false
  },
  {
    __config__: {
      label: "多选题",
      tag: "el-checkbox-group",
      tagIcon: "checkbox",
      defaultValue: [],
      span: 24,
      showLabel: true,
      labelWidth: null,
      layout: "colFormItem",
      optionType: "default",
      required: true,
      regList: [],
      changeTag: true,
      border: false,
      document: "https://element.eleme.cn/#/zh-CN/component/checkbox"
    },
    __slot__: {
      options: [
        {
          label: "选项一",
          value: 1
        },
        {
          label: "选项二",
          value: 2
        }
      ]
    },
    style: {},
    size: "medium",
    min: null,
    max: null,
    disabled: false
  },
  {
    __config__: {
      label: "评分",
      tag: "el-rate",
      tagIcon: "rate",
      defaultValue: 0,
      span: 24,
      showLabel: true,
      labelWidth: null,
      layout: "colFormItem",
      required: true,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/rate"
    },
    style: {},
    max: 5,
    "allow-half": false,
    "show-text": false,
    "show-score": false,
    disabled: false
  },
  {
    __config__: {
      label: "上传",
      tag: "el-upload",
      tagIcon: "upload",
      layout: "colFormItem",
      defaultValue: null,
      showLabel: true,
      labelWidth: null,
      required: true,
      span: 24,
      showTip: false,
      buttonText: "点击上传",
      regList: [],
      changeTag: true,
      fileSize: 2,
      sizeUnit: "MB",
      document: "https://element.eleme.cn/#/zh-CN/component/upload"
    },
    __slot__: {
      "list-type": true
    },
    action: "https://jsonplaceholder.typicode.com/posts/",
    disabled: false,
    accept: "",
    name: "file",
    "auto-upload": true,
    "list-type": "text",
    multiple: false
  }
]
