<template>
  <div class="work-manage">
  <!-- <el-breadcrumb separator="/" class="bread-crumb">
    <el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in breadList" :key="index">
      {{item.name}}
    </el-breadcrumb-item>
  </el-breadcrumb> -->
    <div class="work-manage-wrapper manage-wrapper">
      <div class="work-manage-container">
        <el-tabs v-model="activeName" @tab-click="handleClick" class="mytab">
          <el-tab-pane label="作业详情" name="first" class="first flex-between">
            <!-- 中部生成的表单 -->
            <div class="center-board" style="height:100%">
              <el-scrollbar class="center-scrollbar" style="height:100%">
                <el-row class="center-board-row" :gutter="formConf.gutter">
                  <el-form :size="formConf.size" :label-position="formConf.labelPosition" :disabled="formConf.disabled"
                    :label-width="formConf.labelWidth + 'px'">
                    <draggable class="drawing-board" :list="drawingList" :animation="340" group="componentsGroup"
                      :options="dragOptions">
                      <div class="explain">
                        <div class="explain_title"><span>{{ workData.workName }}</span></div>
                        <div class="explain_item">
                          作业主题：<span>{{ workData.themeName }}</span>
                        </div>
                        <div class="explain_item flex-layout">
                          <div class="work_title" style="flex-shrink:0;">作业说明：</div>
                          <div v-html="explain"></div>
                        </div>
                        <div class="explain_item">
                          提交时间：<span>{{ timerange.beginTime }}---{{
                          timerange.endTime
                        }}</span>
                        </div>
                        <div class="explain_item">积分：<span>100积分</span> </div>
                        <div class="explain_item">能力标准：<span>{{ powers }}</span></div>
                      </div>
                      <div class="work_content">
                        <h3 class="work-title">作业内容</h3>
                        <template v-if="worklist.length==0">
                          <!-- <Empty  :text="'无需要批改的学员'"></Empty> -->
                          <div class="nothing-box flex-align">
                            <div class="nothing-wrap">
                              <img src="@/assets/images/nothing.png" alt="">
                              <p>暂无作业</p>
                              <div v-if="themetype!=4">
                                <draggable-item v-for="(item, index) in drawingList" :key="item.renderKey"
                                  :drawing-list="drawingList" :current-item="item" :index="index" :active-id="activeId"
                                  :form-conf="formConf" @activeItem="activeFormItem" @copyItem="drawingItemCopy"
                                  @deleteItem="drawingItemDelete">
                                </draggable-item>
                              </div>
                              <div class="speak" v-else>
                                <div class="speakcontent">
                                  <div class="speakquest type_one" v-if="speak_odd">
                                    <div class="speak_title">{{speak_odd.__config__.label}}</div>
                                    <div class="speak_detail">
                                      <span v-for="(item,index) in speak_odd.wordString" :key="index"
                                        class="word_item flex-align-center">{{item}}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div v-if="themetype!=4">
                            <template v-if="!worktype||worktype.score=='false'">
                              <draggable-item2 v-for="(item, index) in drawingList" :key="item.renderKey"
                                :drawing-list="drawingList" :current-item="item" :index="index" :active-id="activeId"
                                :form-conf="formConf" @activeItem="activeFormItem" @copyItem="drawingItemCopy"
                                @deleteItem="drawingItemDelete">
                              </draggable-item2>
                            </template>
                            <template v-else>
                              <draggable-item v-for="(item, index) in drawingList" :key="item.renderKey"
                                :drawing-list="drawingList" :current-item="item" :index="index" :active-id="activeId"
                                :form-conf="formConf" @activeItem="activeFormItem" @copyItem="drawingItemCopy"
                                @deleteItem="drawingItemDelete">
                              </draggable-item>
                            </template>

                          </div>
                          <div class="speak" v-else>
                            <div class="speakcontent">
                              <div class="speakquest type_one" v-if="speak_odd">
                                <div class="speak_title">{{speak_odd.__config__.label}}</div>
                                <div class="speak_detail">
                                  <span v-for="(item,index) in speak_odd.wordString" :key="index"
                                    class="word_item flex-align-center">{{item}}</span>
                                </div>
                              </div>
                              <div class="speakquest type_two" v-if="speak_both">
                                <div class="speak_title">{{speak_both.__config__.label}}</div>
                                <div class="speak_detail">
                                  <span v-for="(item,index) in speak_both.wordString" :key="index"
                                    class="word_items flex-align-center">{{item}}</span>
                                </div>
                              </div>
                              <div class="speakquest type_three" v-if="speak_read">
                                <div class="speak_title">{{speak_read.__config__.label}}</div>
                                <p class="speak_detail_read" v-html="speak_read.wordString[0]"></p>
                              </div>
                              <div class="speakquest type_four" v-if="speak_choose">
                                <div class="speak_title">{{speak_choose.__config__.label}}</div>
                                <div class="option">1.{{speak_choose.wordString[0]}}</div>
                                <div class="option">2.{{speak_choose.wordString[1]}}</div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </draggable>
                  </el-form>
                </el-row>
              </el-scrollbar>
            </div>
            <!-- 右侧的作业列表 -->
            <div class="worklist-wrapper" v-if="activeName == 'first'">
              <div class="top flex-align-between">
                <h3>作业列表</h3>
                <div class="creatework">
                  <div @click="creatework"
                    v-if="!hideUsertype[$route.query.projectId]||(hideUsertype[$route.query.projectId]&&hideUsertype[$route.query.projectId].indexOf(userId)<0)"
                    class="flex-align csp">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span>创建作业</span>
                  </div>
                </div>
              </div>
              <ul class="worklist">
                <li v-for="(item, index) in worklist" :key="index" class="work_item"
                  :class="activework == index ? 'work_active' : ''" @click="choosework(index, item)">
                  <div class="item_name wes" :class="titleColor(item)">
                    <!-- <div class="item_logo"></div> -->

                    <p class="wes">{{ item.title2 }}</p>
                  </div>
                  <div class="item_bottom_part">
                    <div class="item_number">
                      提交人数：{{ item.answerMembers }}/{{ item.totalMembers }}
                    </div>
                    <div class="item_course wes">对应课程：{{ item.bindingName || '--'}}</div>
                    <div class="item_time">
                      <span>{{ item.title.beginTime.replace(/-(?=[^-]*$)/, ' ')}} - {{ item.title.endTime.replace(/-(?=[^-]*$)/, ' ') }}</span>
                      <!-- <span>{{ dayjs(item.title.beginTime).format('YYYY-MM-DD HH:mm') }} -
                        {{ dayjs(item.title.endTime).format('YYYY-MM-DD HH:mm') }}</span> -->
                      <!-- <div class="item_status">
                      {{
                    item.status == 0
                      ? "未发布"
                      : item.status == 1
                      ? "未开始"
                      : item.status == 2
                      ? "进行中"
                      : item.status == 3
                      ? "已结束"
                      : ""
                  }}
                    </div> -->
                    </div>
                    <div
                      v-if="!hideUsertype[$route.query.projectId]||(hideUsertype[$route.query.projectId]&&hideUsertype[$route.query.projectId].indexOf(userId)<0)"
                      class="item_menu flex-align-around" :class="titleColor(item)">
                      <span @click.stop="copywork(item.id)">复制</span>
                      <div></div>
                      <span @click.stop="edit(index, item.id)">编辑</span>
                      <div></div>
                      <span @click.stop="remove(index, item.id)">删除</span>
                    </div>
                  </div>
                </li>
              </ul>
              <div v-for="(item, index) in worklist" :key="index" class="work_item"
                :class="activework == index ? 'work_active' : ''" @click="choosework(index, item)" v-if="false">
                <div class="item_name wes" :class="titleColor(item)">
                  <!-- <div class="item_logo"></div> -->
                  <span>{{ item.title2 }}</span>
                </div>
                <div class="item_bottom_part">
                  <div class="item_number">
                    提交人数：{{ item.answerMembers }}/{{ item.totalMembers }}
                  </div>
                  <div class="item_course wes">对应课程：{{ item.bindingName }}</div>
                  <div class="item_time">
                    <span>{{ dayjs(item.title.beginTime).format('YYYY-MM-DD HH:mm') }} -
                      {{ dayjs(item.title.endTime).format('YYYY-MM-DD HH:mm') }}</span>
                    <!-- <div class="item_status">
                      {{
                    item.status == 0
                      ? "未发布"
                      : item.status == 1
                      ? "未开始"
                      : item.status == 2
                      ? "进行中"
                      : item.status == 3
                      ? "已结束"
                      : ""
                  }}
                    </div> -->
                  </div>
                  <div class="item_menu flex-align-around" :class="titleColor(item)">
                    <span @click.stop="copywork(item.id)">复制</span>
                    <div></div>
                    <span @click.stop="edit(index, item.id)">编辑</span>
                    <div></div>
                    <span @click.stop="remove(index, item.id)">删除</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- 复制作业弹窗 -->
            <el-dialog title="复制作业" :visible.sync="dialogVisible" width="30%">
              <el-form>
                <el-form-item label="修改作业名称" label-width="120px">
                  <el-input v-model="workname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="学员提交日期" label-width="120px">
                  <el-date-picker v-model="timerange2" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width:100%">
                  </el-date-picker>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmCopy">确 定</el-button>
              </span>
            </el-dialog>
          </el-tab-pane>
          <el-tab-pane label="批改作业" name="second">
            <checkMode v-if="activeName == 'second'" :themetype="themetype" :workid="defaultWorkId"></checkMode>
          </el-tab-pane>
          <el-tab-pane label="点评作业" name="third" v-if="themetype!=4">
            <commentWork v-if="activeName == 'third'" :workid="defaultWorkId" :themetype="themetype"></commentWork>
          </el-tab-pane>
          <el-tab-pane label="作业数据" name="fourth">
            <!-- <workData></workData> -->
            <workLearnerData v-if="activeName == 'fourth'" :work="currentWork" :themeId="this.$route.query.themeId">
            </workLearnerData>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import {
    debounce
  } from "throttle-debounce";
  import {
    saveAs
  } from "file-saver";
  import ClipboardJS from "clipboard";
  import render from "@/components/render/render";
  // import FormDrawer from "./FormDrawer";
  import JsonDrawer from "./JsonDrawer";
  import RightPanel from "./RightPanel";
  import {
    inputComponents,
    selectComponents,
    // layoutComponents,
    formConf,
  } from "@/components/generator/config_view";
  import {
    exportDefault,
    beautifierConf,
    isNumberStr,
    titleCase,
    deepClone,
  } from "@/utils/index";
  import {
    makeUpHtml,
    vueTemplate,
    vueScript,
    cssStyle,
  } from "@/components/generator/html";
  import {
    makeUpJs
  } from "@/components/generator/js";
  import {
    makeUpCss
  } from "@/components/generator/css";
  // import drawingDefalut from "@/components/generator/drawingDefalut";
  import logo from "@/assets/logo.png";
  import CodeTypeDialog from "./CodeTypeDialog";
  import DraggableItem from "./DraggableItem_workMan";
  import DraggableItem2 from "./DraggableItem_workMan2";
  import {
    getDrawingList,
    saveDrawingList,
    getIdGlobal,
    saveIdGlobal,
    getFormConf,
  } from "@/utils/db";
  import loadBeautifier from "@/utils/loadBeautifier";
  //引入作业批改模块
  import checkMode from "./check_mode";
  import commentWork from "./CommentWork";
  import workData from "./work_data";
  import workLearnerData from "./work_learner_data";

  let beautifier;
  const emptyActiveData = {
    style: {},
    autosize: {}
  };
  let oldActiveId;
  let tempActiveData;
  const drawingListInDB = getDrawingList();
  const formConfInDB = getFormConf();
  const idGlobal = getIdGlobal();

  export default {
    components: {
      draggable,
      render,
      // FormDrawer,
      JsonDrawer,
      RightPanel,
      CodeTypeDialog,
      DraggableItem,
      DraggableItem2,
      checkMode,
      commentWork,
      workData,
      workLearnerData,
    },
    data() {
      return {
        hideUsertype:{
          238:[14549,14550,14551,14552,14553,14554,14555,14556,14557],
          248:[8855,8856,8857],
          251:[10074,10075,10076,10077,10078,10079,10080,10081,10082,10083,10084,10085,10086,10087,10088,
          10089,10090,10091,10092,10093,10094,10095,10096,10097,10098,10099,10100,10101,10102,10103,10104,
          10105,10106,10107,10108,10109,10110,10111,10112,10113,10114,10115,10116,10117,10118,10119,10120,
          10121,10122,10123,10124,10125,10126,10127,10128,10129,10130,10131,10132,10133,10134,10135,10136,
          10137,10138,10139,10140,10141,10142,10143,10144,10145,10146,10147,10148,10149,10150,10151,10152,
          10153,10154,10155,10156,10157,10158,10159,10160,10161,10162,10163,10164,10165,10166,10167,10168,
          10169,10170,10171,10172,10173,10174,10175,10176,10177,10178,10179,10180,10181,10182,10183,10184,
          10185,10186,10187,10188],
        },
        // userId: JSON.parse(sessionStorage.getItem("userinfo")).id,
        userId: undefined,
        isBack: false,
        dragOptions: {
          sort: false, //定义生成的表单是否可以拖拽
        },
        logo,
        idGlobal,
        formConf,
        inputComponents,
        selectComponents,
        // layoutComponents,
        labelWidth: 100,
        drawingList: [],
        drawingData: {},
        activeId: "",
        drawerVisible: false,
        formData: {},
        dialogVisible: false,
        jsonDrawerVisible: false,
        generateConf: null,
        showFileName: false,
        activeData: "",
        saveDrawingListDebounce: debounce(340, saveDrawingList),
        saveIdGlobalDebounce: debounce(340, saveIdGlobal),
        explain: "", //自定义的作业说明
        activeNames: ["1"], //自定义的作业说明展开内容
        activework: 0,
        activeName: "first",
        currentTab: "作业详情",
        worklist: [], //右侧作业列表
        defaultWorkId: null, //默认显示的作业id
        powers: "", //作业说明中的二级能力标准
        timerange: "", //时间范围
        workData: "",
        currentWork: {}, //当前选中的作业
        workname: "", //复制作业修改的作业名称
        timerange2: "", //复制作业的时间选择
        copyid: "", //复制的作业id
        themetype: null, //当前作业主题的类型
        speak_odd: null, //单音节题目
        speak_both: null, //双音节题目
        speak_read: null, //朗读短文题目
        speak_choose: null, //2选1题目
        themebind: null, //绑定课程/活动
        courseId: null, //绑定课程的id
        worktype: null,
        breadList:[
          {name:'首页',path:'/'},
          {name:'研修空间',path:'/home/schedule'},
          {name:'作业管理',path:'/home/manage/job'},
          {name:'查看作业详情'}
        ]
      };
    },
    computed: {},
    watch: {
      // eslint-disable-next-line func-names
      "activeData.__config__.label": function (val, oldVal) {
        if (
          this.activeData.placeholder === undefined ||
          !this.activeData.__config__.tag ||
          oldActiveId !== this.activeId
        ) {
          return;
        }
        this.activeData.placeholder =
          this.activeData.placeholder.replace(oldVal, "") + val;
      },
      activeId: {
        handler(val) {
          oldActiveId = val;
        },
        immediate: true,
      },
      drawingList: {
        handler(val) {
          this.saveDrawingListDebounce(val);
          if (val.length === 0) this.idGlobal = 100;
        },
        deep: true,
      },
      idGlobal: {
        handler(val) {
          this.saveIdGlobalDebounce(val);
        },
        immediate: true,
      },
    },
    mounted() {
      this.userId = this.$store.state.user.userInfo.id
      this.activeName = "first";
      if (formConfInDB) {
        this.formConf = formConfInDB;
      }
    },
    activated() {
      this.userId = this.$store.state.user.userInfo.id
      this.getlist();
      this.getthemeinfo()
      this.activeName = "first";
    },
    methods: {
    //选择时间对比
      compareTime(begintime, endtime) {
        let btime = begintime.split("")
        btime.splice(10, 1, " ")
        let etime = endtime.split("")
        etime.splice(10, 1, " ")
        if (new Date(etime.join("")).getTime() <= new Date(btime.join("")).getTime()) { return false } else { return true }
        },
      // 右侧列表名称底部颜色计算
      titleColor(item) {
        if (item.title.subagain) {
          let endTime = item.title.subEnd.split("");
          endTime.splice(10, 1, " ");
          // console.log('补交结束时间戳',new Date(endTime.join("")).getTime());
          // console.log('当前时间戳',Date.now());
          if (item.status == '3' && Date.now() < new Date(endTime.join("")).getTime()) {
            return "hahSubAgain"
          }
          if (item.status == '3' && Date.now() >= new Date(endTime.join("")).getTime()) {
            return "finish"
          }
          if (item.status == '1') return 'not-start'

        } else {
          if (item.status == '3') return 'finish'
          if (item.status == '1') return 'not-start'
        }

      },
      // 创建作业
      creatework() {
        // localStorage.removeItem("drawingItems")
        let breadLength = this.$store.state.basics.breadList.length
         this.$router.push({
          path: "/edit",
          query: {
            workId: '-1',
            themeId: this.$route.query.themeId,
            createId: this.$route.query.createId,
            themetype: this.themetype,
            mdQuestionnaireId: this.workData.mdQuestionnaireId,
            themebind: this.themebind,
            courseId: this.courseId, //绑定课程的课程id
            breadNumber: breadLength + 1,
            isCreate:true
          },
        });
        // this.$router.push({
        //   path: "/design",
        //   query: {
        //     themeId: this.$route.query.themeId,
        //     // createId: JSON.parse(sessionStorage.getItem("userinfo")).id,
        //     createId: this.$store.state.user.userInfo.id,
        //     themetype: this.themetype,
        //     themebind: this.themebind,
        //     courseId: this.courseId, //绑定课程的课程id
        //     breadNumber: breadLength + 1,
        //   },
        // });
      },
      // 复制作业
      copywork(id) {
        // console.log("复制作业", id);
        this.workname = '';
        this.timerange2 = [];
        this.copyid = id;
        this.dialogVisible = true;
      },
      confirmCopy() {
        if (!this.workname.trim()) {
          this.$alert("请填写作业名称", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        if (this.timerange2.length != 2) {
          this.$alert("请选择作业提交时间", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        //  判断复制的作业开始时间与结束时间
        if(!this.compareTime(this.timerange2[0],this.timerange2[1])){
          this.$message({
            message: '作业结束时间应大于开始时间',
            type: 'warning'
          });
          return
        }
        this.dialogVisible = false;
        let params = {
          id: this.copyid,
          copyWorkName: this.workname,
          startRestriction: this.timerange2[0],
          endRestriction: this.timerange2[1],
        };
        // console.log("复制问卷参数", params);
        this.$Api.Form.copywork(params)
          .then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.getlist();
            }
          })
          .catch((err) => {
            console.log(err);
          });
        // console.log(this.copyid)
        // console.log(this.timerange2)
        // console.log(this.workname)
      },
      // 编辑作业
      edit(index, id) {
        let breadLength = this.$store.state.basics.breadList.length
        this.$router.push({
          path: "/edit",
          query: {
            workId: id,
            themeId: this.$route.query.themeId,
            createId: this.$route.query.createId,
            themetype: this.themetype,
            mdQuestionnaireId: this.workData.mdQuestionnaireId,
            themebind: this.themebind,
            courseId: this.courseId, //绑定课程的课程id
            breadNumber: breadLength + 1,
          },
        });
      },
      remove(index, id) {
        // console.log("删除的问卷id---", id);
        this.$confirm("是否删除该作业？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "info",
          })
          .then(() => {
            this.$Api.Form.deleteForm(id)
              .then((res) => {
                // console.log(res);
                //删除成功重新获取该主题下的作业列表
                this.getlist();
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消成功",
            });
          });
      },
      //选择作业
      choosework(index, item) {
        this.activework = index;
        this.defaultWorkId = item.id;
        this.currentWork = item;
        this.getForm(item.id);
        this.getPower(item.id);
      },
      //切换tab
      handleClick(tab) {
        this.currentTab = tab.label;
        // console.log(tab.label);
      },
      //获取该问卷下所有题目的能力标准
      async getPower(workid) {
        let workContent = await this.getForm(workid);
        // console.log('123--',workContent)
        this.$Api.Form.getPower(workid)
          .then((value) => {
            // console.log('456--',value)
            workContent.map((item) => {
              if (value.data.hasOwnProperty(item.__vModel__) == true) {
                item.__config__.powerVal = value.data[item.__vModel__];
              }
            });
            // console.log("带有能力标准的问卷---", workContent);
            localStorage.setItem("drawingItems", JSON.stringify(workContent));
            this.drawingList = JSON.parse(localStorage.getItem("drawingItems"));
            this.activeId = this.drawingList[0].formId;
            this.activeData = this.drawingList[0];
          })
          .catch((reason) => {
            console.log(reason);
          });
      },
      //获取专家创建的问卷信息
      getForm(workid) {
        // console.log('作业列表返回的默认id--',workid)
        //获取该问卷对应的二级能力标准
        this.$Api.Form.getSecondPower(workid)
          .then((value) => {
            // console.log("二级能力标准", value);
            let str = "";
            value.data.map((item) => {
              return (str += item.title + ",");
            });
            this.powers = str.slice(0, str.length - 1);
          })
          .catch((reason) => {
            console.log(reason);
          });
        // console.log(workid)
        return this.$Api.Form.getTeaForm(workid)
          .then((value) => {
            // console.log("问卷信息----", value);
            this.workData = value.data;
            this.worktype = JSON.parse(value.data.title)
            this.timerange = JSON.parse(value.data.title);
            let workContent = JSON.parse(value.data.content);
            // console.log("问卷信息---", this.worktype);

            workContent.map((item) => {
              if (item.__config__.tag == "tinymce") {
                this.explain = item.__config__.defaultValue;
              }
              if (item.__config__.tag == "el-checkbox-group") {
                item.__config__.defaultValue = [];
              } else {
                item.__config__.defaultValue = "";
              }
              if (item.__config__.tag == "el-input") {
                item.readonly = true;
              } else {
                item.disabled = true;
              }
              workContent = workContent.filter((item) => {
                // return item.__vModel__ != "explain";
                return item.__config__.tag != "tinymce";
              });
              try {
                // console.log("这是普通话作业")
                if (item.questionType == 1) {
                  this.speak_odd = item
                } else if (item.questionType == 2) {
                  this.speak_both = item
                } else if (item.questionType == 3) {
                  this.speak_read = item
                } else if (item.questionType == 4) {
                  this.speak_choose = item
                }
              } catch (err) {
                console.log("一般的作业，不做操作")
              }

            });
            // console.log(workContent);
            let resultList = JSON.parse(value.data.answer);
            // console.log(resultList);
            // console.log(workContent);
            workContent.map((item) => {
              if (resultList.hasOwnProperty(item.__vModel__) == true) {
                if (item.__config__.tag == "el-checkbox-group") {
                  item.__config__.defaultValue = resultList[item.__vModel__].sort();
                } else {
                  item.__config__.defaultValue = resultList[item.__vModel__];
                }
              }
            });
            return workContent;
          })
          .catch((reason) => {
            console.log(reason);
          });
      },
      //获取作业主题信息
      getthemeinfo() {
        this.$Api.Form.getThemeInfo(this.$route.query.themeId)
          .then(res => {
            // console.log("作业主题信息--", res)
            this.themetype = res.data.themeType
            this.themebind = res.data.themeBinding
            this.courseId = res.data.bindingId
          })
        .catch(err=>{console.log("获取作业主题信息失败--",err)})
      },

      //获取当前主题下的问卷列表
      getlist() {
        this.$Api.Form.getFormList(this.$route.query.themeId)
          .then((res) => {
            // console.log(res);
            //判断当前主题下有没有问卷
            if (res.data.length == 0) {
              localStorage.setItem("drawingItems", JSON.stringify([]));
              this.drawingList = JSON.parse(localStorage.getItem("drawingItems"));
              this.explain = "";
              this.worklist = [];
              this.powers = "";
              this.workData = "";
              this.timerange = "";
              throw "当前主题下暂无作业"; //return Promise.reject('当前主题下暂无作业')
            } else {
              let currentTime = new Date().getTime();
              // console.log('当前时间戳--',currentTime)
              //循环判断每个作业的状态 0 -- 未发布，1 -- 未开始，2 -- 进行中，3 -- 已结束
              res.data.map((item) => {
                item.title2 = JSON.parse(item.title).workname;
                item.title = JSON.parse(item.title);
                item.status =
                  currentTime > new Date(item.startRestriction.replace(/-/g, '/')).getTime() &&
                  currentTime < new Date(item.endRestriction.replace(/-/g, '/')).getTime() ?
                  "2" :
                  currentTime < new Date(item.startRestriction.replace(/-/g, '/')).getTime() ?
                  "1" :
                  currentTime > new Date(item.endRestriction.replace(/-/g, '/')).getTime() ?
                  "3" :
                  "4";
              });
              this.worklist = res.data.reverse();
              this.defaultWorkId = res.data[0].id;
              this.currentWork = res.data[0];
              return res.data[0].id; // return Promise.resolve(res.data[0].id)
            }
          })
          .then((value) => {
            this.getForm(value);
            this.getPower(value);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      activeFormItem(currentItem) {
        this.activeData = currentItem;
        this.activeId = currentItem.__config__.formId;
      },
      onEnd(obj) {
        if (obj.from !== obj.to) {
          this.activeData = tempActiveData;
          this.activeId = this.idGlobal;
        }
      },
      addComponent(item) {
        const clone = this.cloneComponent(item);
        this.drawingList.push(clone);
        this.activeFormItem(clone);
      },
      cloneComponent(origin) {
        const clone = deepClone(origin);
        const config = clone.__config__;
        config.span = this.formConf.span; // 生成代码时，会根据span做精简判断
        this.createIdAndKey(clone);
        clone.placeholder !== undefined && (clone.placeholder += config.label);
        tempActiveData = clone;
        return tempActiveData;
      },
      createIdAndKey(item) {
        const config = item.__config__;
        config.formId = ++this.idGlobal;
        config.renderKey = `${config.formId}${+new Date()}`; // 改变renderKey后可以实现强制更新组件
        if (config.layout === "colFormItem") {
          item.__vModel__ = `field${this.idGlobal}`;
        } else if (config.layout === "rowFormItem") {
          config.componentName = `row${this.idGlobal}`;
          !Array.isArray(config.children) && (config.children = []);
          delete config.label; // rowFormItem无需配置label属性
        }
        if (Array.isArray(config.children)) {
          config.children = config.children.map((childItem) =>
            this.createIdAndKey(childItem)
          );
        }
        return item;
      },
      AssembleFormData() {
        this.formData = {
          fields: deepClone(this.drawingList),
          ...this.formConf,
        };
      },
      generate(data) {
        const func = this[`exec${titleCase(this.operationType)}`];
        this.generateConf = data;
        func && func(data);
      },
      execRun(data) {
        this.AssembleFormData();
        this.drawerVisible = true;
      },
      execDownload(data) {
        const codeStr = this.generateCode();
        const blob = new Blob([codeStr], {
          type: "text/plain;charset=utf-8"
        });
        saveAs(blob, data.fileName);
      },
      execCopy(data) {
        document.getElementById("copyNode").click();
      },
      drawingItemCopy(item, list) {
        let clone = deepClone(item);
        clone = this.createIdAndKey(clone);
        list.push(clone);
        this.activeFormItem(clone);
      },
      drawingItemDelete(index, list) {
        list.splice(index, 1);
        this.$nextTick(() => {
          const len = this.drawingList.length;
          if (len) {
            this.activeFormItem(this.drawingList[len - 1]);
          }
        });
      },
      generateCode() {
        const {
          type
        } = this.generateConf;
        this.AssembleFormData();
        const script = vueScript(makeUpJs(this.formData, type));
        const html = vueTemplate(makeUpHtml(this.formData, type));
        const css = cssStyle(makeUpCss(this.formData));
        return beautifier.html(html + script + css, beautifierConf.html);
      },
      showJson() {
        this.AssembleFormData();
        this.jsonDrawerVisible = true;
      },
      tagChange(newTag) {
        newTag = this.cloneComponent(newTag);
        const config = newTag.__config__;
        newTag.__vModel__ = this.activeData.__vModel__;
        config.formId = this.activeId;
        config.span = this.activeData.__config__.span;
        this.activeData.__config__.tag = config.tag;
        this.activeData.__config__.tagIcon = config.tagIcon;
        this.activeData.__config__.document = config.document;
        if (
          typeof this.activeData.__config__.defaultValue ===
          typeof config.defaultValue
        ) {
          config.defaultValue = this.activeData.__config__.defaultValue;
        }
        Object.keys(newTag).forEach((key) => {
          if (this.activeData[key] !== undefined) {
            newTag[key] = this.activeData[key];
          }
        });
        this.activeData = newTag;
        this.updateDrawingList(newTag, this.drawingList);
      },
      updateDrawingList(newTag, list) {
        const index = list.findIndex(
          (item) => item.__config__.formId === this.activeId
        );
        if (index > -1) {
          list.splice(index, 1, newTag);
        } else {
          list.forEach((item) => {
            if (Array.isArray(item.__config__.children))
              this.updateDrawingList(newTag, item.__config__.children);
          });
        }
      },
      refreshJson(data) {
        this.drawingList = deepClone(data.fields);
        delete data.fields;
        this.formConf = data;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/styles/home";

  .work-manage {
    width: 100%;
    height: 100%;
    // background-color: #f9f9f9;
     .bread-crumb {
        width: 990px;
        margin: 0 auto;

        @media screen and (min-width:1250px) {
        width: 1200px;
        }
     }
    .work-manage-container {
      margin: 0 auto 100px;
      background-color: #ffffff;
      box-shadow: 0px 2px 10px 0px #f5f8fa;
      border-radius: 8px;
    }

    .mytab {

      .center-board {
        margin: 0 0 0 0;
        width: 690px;

        .center-scrollbar {
          border-left: 0;
          border-right: 0;
        }

        .explain {
          margin-bottom: 15px;
          padding: 22px 30px 20px;
          border-bottom: 1px solid #f2f2f2;
          box-sizing: border-box;
          overflow: hidden;

          .explain_title {
            font-size: 13px;
            font-weight: bold;
            margin-bottom: 12px;
          }

          .explain_item {
            margin-bottom: 8px;
            font-size: 12px;

            span {
              color: #666;
            }
          }
        }

        .work_content {
          padding: 0 30px 0 33px;

          .work-title {
            font-size: 13px;
            font-weight: bold;
            margin-bottom: 15px;
          }

          .nothing-box {
            height: 90%;

            .nothing-wrap {
              margin: 55px auto;
            }

            img {
              max-width: 100%;
              vertical-align: middle;
            }

            p {
              text-align: center;
              margin-top: -68px;
              font-size: 16px;
              color: #999;
            }
          }

          .drawing-item {
            border: 1px solid #f2f2f2;
            border-radius: 8px;
            margin-bottom: 25px;
          }
        }

        .speak {
          width: 100%;

          .speakcontent {
            margin: 0 10px;

            .speakquest {
              .speak_title {
                font-weight: bold;
                margin: 20px 0;
                font-size: 17px;
              }

              .option {
                margin-left: 50px;
              }

              .speak_detail {
                width: 400px;
                height: 400px;
                margin: 20px auto;
                display: flex;
                flex-wrap: wrap;
                font-size: 14px;

                .word_item {
                  width: 10%;
                  height: 10%;
                }

                .word_items {
                  width: 20%;
                  height: 10%;
                }
              }

              .speak_detail_read {
                margin: 0 50px;

              }
            }
          }
        }
      }

      .worklist-wrapper {
        // padding: 0 17px;
        min-width: 300px;
        border-left: 1px solid #f2f2f2;

        .top {
          padding: 0 17px;
          border-bottom: 1px solid #f9f9f9;

          h3 {
            line-height: 40px;
            font-size: 13px;
            box-sizing: border-box;
            color: #555;
          }

        }

        .creatework {
          .el-icon-circle-plus-outline {
            font-size: 16px;
            color: #508EF9;
            margin-right: 11px;
          }

          span {
            font-size: 12px;
            color: #595959;
          }
        }

        .worklist {
          padding: 0 17px;
          flex: 1;
          max-height: 710px;
          overflow: hidden;
          overflow-y: auto;
          background: #ffffff;
          border-radius: 5px;
        }

        .work_item {
          margin: 17px auto 22px;
          border-radius: 10px;
          // box-sizing: border-box;
          border: 1px solid #f2f2f2;
          border-radius: 10px;
          overflow: hidden;
          cursor: pointer;
          transition: 0.5s all ease;

          &.work_active,
          &:hover {
            box-shadow: 0px 3px 6px 0px rgba(60, 108, 195, 0.19);
            border: 1px solid #cccccc;
          }

          .item_name {
            padding-left: 16px;
            line-height: 33px;
            color: #fff;
            font-size: 12px;
            font-weight: bold;
            background: linear-gradient(96deg, #FF6464 0%, #FF5F5F 100%);
            position: relative;

            &>p {
              width: 195px;
              line-height: 33px;
            }

            &::before,
            &::after {
              content: ' ';
              position: absolute;
              display: block;
              top: 0px;
              background-size: cover;
            }

            &::before {
              left: 0;
              width: 53px;
              height: 33px;
              background-image: url('../../assets/images/work/title-bg.png');
            }

            &::after {
              right: 0;
              width: 43px;
              height: 29px;
              background-image: url('../../assets/images/work/living.png');
            }

            &.not-start {
              background: linear-gradient(98deg, #C567E7 0%, #C771E6 100%);

              &::after {
                background-image: url('../../assets/images/work/unstart.png');
              }
            }

            &.finish {
              background: rgba(0, 0, 0, .1);
              color: #666;

              &::after {
                background-image: url('../../assets/images/work/end.png');
              }

            }

            &.hahSubAgain {

              &::after {
                background-image: url('../../assets/images/work/end.png');
              }
            }
          }




          .item_bottom_part {
            padding: 10px 22px 14px 16px;
          }

          .item_number,
          .item_course,
          .item_time {
            font-size: 12px;
            color: #666;
            margin-bottom: 5px;
            font-family: Source Han Sans CN;
            max-width: 230px;
          }

          .item_menu {
            font-size: 12px;
            color: #FF5757;

            div {
              width: 1px;
              height: 13px;
              background: #F65454;


            }

            &.not-start {
              color: #C66DE7;

              div {
                background: #C76BE9;
              }
            }

            &.finish {
              color: #666;

              div {
                background: #AAAAAA;
              }
            }
          }
        }
      }

      // .worklist::-webkit-scrollbar {
      //   display: none;
      // }
      .worklist::-webkit-scrollbar {
        width: 6px;
      }

      .worklist::-webkit-scrollbar-track {
        border-radius: 2px;
      }

      .worklist::-webkit-scrollbar-button {
        height: 15px;
      }

      .worklist::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 4px;
      }

      ::v-deep .el-tabs__header {
        margin: 0 !important;
      }

      ::v-deep .el-upload {
        display: none;
      }
    }


    @media screen and (min-width:1250px) {
      .mytab {
        .center-board {
          width: 830px;

          .explain {
            padding: 22px 40px 20px;

            .explain_title {
              font-size: 16px;
              margin-bottom: 15px;
            }

            .explain_item {
              margin-bottom: 10px;
              font-size: 14px;
            }
          }

          .work_content {
            padding: 0 45px 0 40px;

            .work-title {
              font-size: 16px;
              margin-bottom: 23px;
            }

            .nothing-box {
              height: 90%;

              .nothing-wrap {
                margin: 55px auto;
              }

              img {
                max-width: 100%;
                vertical-align: middle;
              }

              p {
                text-align: center;
                margin-top: -68px;
                font-size: 16px;
                color: #999;
              }
            }

            .drawing-item {
              margin-bottom: 30px;
            }
          }
        }


        .worklist-wrapper {
          // padding: 0 20px;
          min-width: 300px;
          .top {
            h3 {
              line-height: 49px;
              font-size: 16px;
            }
          }

          .creatework {
            .el-icon-circle-plus-outline {
              font-size: 19px;
              margin-right: 13px;
            }
          }

          .worklist {
            padding: 0 20px;
          }

          .work_item {
            margin: 21px auto 27px;

            .item_name {
              padding-left: 19px;
              line-height: 40px;
              font-size: 14px;

              &>p {
                width: 230px;
                line-height: 40px;
              }

              &::before {
                width: 64px;
                height: 40px;
              }

              &::after {
                width: 52px;
                height: 35px;
              }
            }

            .item_bottom_part {
              padding: 15px 27px 17px 19px;
            }

            .item_number,
            .item_course,
            .item_time {
              font-size: 14px;
              margin-bottom: 7px;
              max-width: 270px;
            }

            .item_menu {
              font-size: 14px;
            }
          }
        }

      }


    }
  }
</style>