<template>
  <div class="comment-box">
    <div class="operation flex-align-between">
      <div>
        <span class="operation-name">点评状态：</span>
        <el-select v-model="value1" placeholder="请选择" size="mini" class="select" style="width:110px;">
          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <span class="operation-name">排序方式：</span>
        <el-select v-model="value2" placeholder="请选择" size="mini" class="select" style="width:140px;">
          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <!-- <el-input placeholder="请输入学员姓名" style="width:176px" size="mini" suffix-icon="el-icon-search" v-model="input1">
        </el-input> -->
      </div>
      <!-- <el-button class="btn">批量点评</el-button> -->
    </div>
    <!-- <div class="tips">
      <i class="el-icon-warning" style="color: #07D14A;margin-right:10px"></i>
      已选<span style="color: #07D14A;"> 0 </span>项。
      <span style="color: #2373EC;">
        点击选择全部
        <span style="color: #07D14A;">201</span>
        项
      </span>
    </div> -->
    <div class="selected" v-if="hasstu">
      <el-checkbox v-model="checked" @change="checkall">单页全选</el-checkbox>
    </div>
    <div class="item-box" v-for="(item, index) in stuList" :key="index">
      <el-checkbox style="margin:0 0 28px 19px" v-model="item.isCheck" @change="checkitem(item)">
      </el-checkbox>
      <div class="item">
        <div class="item-info flex-align">
          <div class="avatar">
            <img :src="imgurl + item.userAvatar" :onerror="imgUrl" />
          </div>
          <div class="userinfo">
            <div class="name">
              <span>{{ item.userName }}</span>
              <el-rate v-model="item.correctingContent.score" disabled v-if="item.correctingContent"></el-rate>
              <span>{{
                item.correctingContent
                  ? item.correctingContent.score + ".0分"
                  : null
              }}</span>
            </div>
            <div class="time">提交于{{ item.createTime }}</div>
          </div>
        </div>
        <div class="num-box flex-align-around" v-if="themetype!=4">
          <div>
            <span class="num">{{ item.totalPoints.toFixed(1) }}</span>
            <br />总得分
          </div>
          <div>
            <span class="num">{{ item.objectiveScore }}</span>
            <br />客观题得分
          </div>
          <div>
            <span class="num">{{ item.objectiveAccuracy }}%</span>
            <br />客观题正确率
          </div>
          <div>
            <span class="num">{{ item.subjectiveScore.toFixed(1) }}</span>
            <br />主观题得分
          </div>
          <div>
            <span class="num" v-if="themetype==4">
              {{ item.answerDuration}}
            </span>
            <span class="num" v-else>
              {{ item.answerDuration.split("分钟")[0] }}
              <span style="font-size:15px;font-weight: normal">分</span>
              {{ item.answerDuration.split("分钟")[1].split("秒")[0] }}
              <span style="font-size:15px;font-weight: normal">秒</span>
            </span>

            <br />答题时长
          </div>
        </div>
        <div class="num-box flex-align-around" v-if="themetype==4">
          <div>
            <span class="num">{{item.totalPoints}}</span>
            <br />总得分
          </div>
          <div>
            <span class="num">90</span>
            <br />单音节得分
          </div>
          <div>
            <span class="num">80</span>
            <br />多音节
          </div>
          <div>
            <span class="num">70</span>
            <br />短文朗读得分
          </div>
          <div>
            <span class="num">{{item.subjectiveScore||0}}</span>
            <br />命题说话得分
          </div>
          <div>
            <span class="num">
              {{ item.answerDuration}}
            </span>
            <br />答题时长
          </div>
        </div>
        <div class="paper-box">
          <stucomment :info="item" @getSubInfo="getSubInfo"></stucomment>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination :page-sizes="[5, 10, 15]" :page-size="pageseize" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" layout="prev, pager, next, sizes" background :total="allsize" v-if="hasstu">
    </el-pagination>

    <div class="empty flex-column-center" v-if="!hasstu">
      <img src="@/assets/images/nothing.png" />
      <p>暂无内容</p>
    </div>
  </div>
</template>

<script>
  import stucomment from "./stucomment";
  export default {
    components: {
      stucomment,
    },
    props: {
      value1: {
        type: String,
        default: "全部"
      },
      value2: {
        type: String,
        default: "按提交时间倒序"
      },
      workid: Number,
      themetype: Number
    },
    data() {
      return {
        options1: [{
          label: "全部",
          value: "全部"
        }],
        options2: [{
          label: "按提交时间倒序",
          value: "按提交时间倒序"
        }],
        userScore: null,
        text: "查看试卷",
        isshow: false,
        input1: "",
        checked: "",
        pageseize: 5,
        currentpage: 1,
        allsize: null,
        stuList: [],
        showComment: false,
        imgurl: this.downloadURL,

        imgUrl: 'this.src="' + require("../../assets/images/home/avatar.png") + '"',
        hasstu: true,
      };
    },
    methods: {
      watchPaper(index) {
        if (!this.isshow) {
          this.currentInd = index;
        } else {
          this.index = -1;
        }
        this.isshow = !this.isshow;
      },
      //单页全选
      checkall(val) {
        console.log("单页全选--", val)
        if (val) {
          //全选
          this.stuList.map(item => {
            item.isCheck = true
          })
        } else {
          //取消全选
          this.stuList.map(item => {
            item.isCheck = false
          })
        }
      },
      //单选
      checkitem(item) {
        // console.log(item)
        this.ischeckall()
      },
      //判断是否全选
      ischeckall() {
        let arr = this.stuList.filter(item => {
          return item.isCheck == false
        })
        if (arr.length == 0) {
          this.checked = true
        } else {
          this,
          this.checked = false
        }
      },
      //分页每页数量发生改变
      handleSizeChange(size) {
        this.checked = false; //取消单页全选
        this.pageseize = size;
        this.getSubInfo();
      },
      //分页当前页码发生改变
      handleCurrentChange(page) {
        this.checked = false; //取消单页全选
        this.currentpage = page;
        this.getSubInfo();
      },
      //获取该作业已提交人员列表
      getSubInfo() {
        let params = {
          questionnaireId: this.workid,
          page: this.currentpage,
          limit: this.pageseize
        };
        this.$Api.Form.getSubInfo(params)
          .then(res => {
            console.log(res);
            if (res.code == 500) {
              this.hasstu = false;
            } else {
              this.hasstu = true;
              this.allsize = res.data.totalCount;
              res.data.list.map(item => {
                if (item.correctingContent) {
                  item.correctingContent = JSON.parse(item.correctingContent);
                }
              });
              console.log(res);
              this.stuList = res.data.list;
              this.stuList.map(item => {
                this.$set(item, "isCheck", false)
              })
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
    mounted() {
      this.getSubInfo();
    }
  };
</script>

<style lang="scss" scoped>
  .comment-box {
    // width: 1200px;
    margin: 0 auto;
    padding: 35px 46px 0;

    .operation {
      .operation-name {
        font-size: 12px;
        color: #999;
      }

      .select {
        margin-right: 20px;
      }
    }

    .btn {
      background-color: #508EF9;
      color: #ffffff;
      padding: 0 25px;
      line-height: 26px;
    }

    .tips {
      height: 36px;
      margin-top: 21px;
      background: rgba(7, 209, 74, 0.07);
      border-radius: 5px;
      line-height: 36px;
      padding-left: 13px;
    }

    .selected {
      margin-top: 26px;
      padding-bottom: 11px;
      border-bottom: 1px solid #f2f2f2;
    }

    .item-box {
      padding-top: 26px;
      margin-bottom: 51px;

      .item {
        // background: rgba(232, 232, 232, 0.2);
        box-sizing: border-box;
        // padding-bottom: 30px;

        .item-info {
          padding: 0px 0 0 19px;

          .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 35px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .userinfo {
            .name {
              span:first-child {
                font-size: 16px;
                font-weight: 500;
                margin-right: 10px;
              }

              span:last-child {
                font-size: 16px;
                color: #ff9900;
              }
            }

            .time {
              color: #666666;
              font-size: 12px;
              margin-top: 3px;
            }
          }
        }

        .num-box {
          height: 103px;
          background: rgba(204, 204, 204, .1);
          border-radius: 10px;
          margin: 20px auto 0;
          text-align: center;

          .num {
            color: #5572E8;
            font-weight: bold;
            font-size: 25px;
          }

          div:nth-child(2) .num {
            color: #36C2CF;
          }

          div:nth-child(3) .num {
            color: #F6B926;
          }

          div:nth-child(4) .num {
            color: #EF5757;
          }

          div:nth-child(5) .num {
            color: #B05BCF;
          }
        }

        .paper-box {
          margin-top: 30px;
        }
      }
    }

    ::v-deep .el-pagination {
      text-align: center;
      margin: 20px 0;

      .btn-prev,
      .btn-next {
        background-color: transparent;
      }

      .el-pager .number {
        color: #777777;
        height: 30px;
        min-width: 30px;
        padding: 0;
        margin: 0 4px
      }

      .el-pager .active {
        color: #fff;
        background-color: #508EF9;
      }

      .el-pager li.active+li {
        border-left: 1px solid #dfdfdf;
      }

      .el-pager li.btn-quicknext,
      .el-pager li.btn-quickprev {
        background-color: transparent;
      }
    }

    .empty {
      width: 100%;
      height: 455px;

      p {
        margin-top: 25px;
        font-size: 16px;
        color: #999;
      }
    }
  }
</style>